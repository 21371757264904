









import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import InstructionCategory from '@/shared/modules/instruction-categories/instruction-category.model'
import { GlobalActions } from '@/shared/store/global/global.actions'
import CreateInstructionCategoriesDialog from
    '@/admin/components/views/instruction-categories/CreateInstructionCategoriesDialog.vue'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import InstructionCategoryService from '@/shared/modules/instruction-categories/instruction-category.service'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import EditInstructionCategoriesDialog
  from '@/admin/components/views/instruction-categories/EditInstructionCategoriesDialog.vue'

@Component({
  components: { DataTable, PageHeader },
  methods: { __ },
})
export default class CompaniesIndex extends Vue {
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
      .setModel(InstructionCategory)
      .setEndpoint('instruction-categories')
      .setDoubleClick(this.openEditDialog)
      .setHeaders([
        new DataTableHeader()
          .setKey('name')
          .setText(__('admin.views.instruction-categories.index.table.columns.name')),
      ])
      .setActions([
        new EditAction()
          .setAction(this.openEditDialog),
        new DeleteAction()
          .setAction(this.deleteCategory),
      ])
  }

  openCreateDialog(): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: CreateInstructionCategoriesDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('admin.components.instruction-categories.create-dialog.on-success'),
          })
        },
      },
    })
  }

  openEditDialog(instructionCategory: InstructionCategory): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: EditInstructionCategoriesDialog,
      maxWidth: 570,
      persistent: true,
      meta: {
        instructionCategory,
        onSuccess: () => {
          this.tableRef.refresh()
          this.$store.dispatch(GlobalActions.closeDialog)
          this.$store.dispatch(GlobalActions.showSnackBar, {
            type: SnackBarTypes.success,
            message: __('admin.components.instruction-categories.edit-dialog.on-success'),
          })
        },
      },
    })
  }

  deleteCategory(instructionCategory: InstructionCategory): void {
    this.$store.dispatch(GlobalActions.showDialog, {
      show: true,
      component: AreYouSureDialog,
      meta: {
        title: __('admin.views.instruction-categories.index.table.action.delete.title'),
        text: __('admin.views.instruction-categories.index.table.action.delete.text', { name: instructionCategory.name }),
        onYes: () => InstructionCategoryService.destroy(instructionCategory.id)
          .then(() => {
            this.tableRef.refresh()
            this.$store.dispatch(GlobalActions.showSnackBar, {
              type: SnackBarTypes.success,
              message: __('admin.views.instruction-categories.index.table.action.delete.on-success'),
            })
          }),
      },
    })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
