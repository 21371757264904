import Service from '@/shared/classes/service'

export default class InstructionCategoryService extends Service {
  static prefix = 'instruction-categories'

  static destroy(id: number): Promise<any> {
    return this.delete(`/${ this.prefix }/${ id }`)
  }

  static show(id: number): Promise<any> {
    return this.get(`/${ this.prefix }/${ id }`)
  }
}
